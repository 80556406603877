import React, { useEffect, useRef, createRef } from 'react';
import Button from '../../../components/atoms/Button';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import Grid from '../../../components/molecules/Grid';
import Box from '../../../components/molecules/Box';
import Typography from '../../../components/atoms/Typography';
import Title from '../../../components/atoms/Title';
import Picture from '../../../components/atoms/Picture';
import { get } from 'lodash';
import useStyles from './style';
import { COMPONENT_NAME } from './locators';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TextRotateAnimation from '../../../components/atoms/TextRotateAnimation';
import { EVENT_LIST_TEMPLATE_NAME } from './constants';
import { EVENTS_THEME_COLOR } from '../../../../constants';
import checkEmptyObject from '../../../utils/checkEmptyObject';

const Infobox = props => {
  const isFieldsEmpty = checkEmptyObject(props.fields);
  const { segmentText, title, image, SEOEnglishCTA1, SEOEnglishCTA2, SEOEnglishCTA3 } = props.fields;

  const componentList = get(
    props,
    'sitecoreContext.route.placeholders["vad-main"][0].placeholders[no-search-result]',
    '[]'
  );
  const contentType = get(
    props,
    'sitecoreContext.route.fields.contentType.fields.taxonomyKey.value',
    ''
  ).toLowerCase();
  const componentLength = componentList && componentList.length;

  const lastComponentObject =
    componentList &&
    componentList.length > 0 &&
    componentList[componentLength - 1];

  let lastComponent = get(props, 'appConfig.lastComponent', '');

  if (
    lastComponent &&
    lastComponent.toLowerCase() === 'globalsearchsublayout'
  ) {
    lastComponent = lastComponentObject && lastComponentObject.componentName;
  }

  const classes = useStyles(props);

  const addAnalyticsInfo = index => {
    const seoText = get(props.fields, `SEOEnglishCTA${index + 1}.value`, '');
    const obj = {
      ...analyticsData.pageBodyInteraction.arrowClick,
      ...{
        label: seoText,
      },
    };
    pushAnaylyticsData(obj);
  };

  const totalLinks = Object.keys(props && props.fields).filter(
    propertyName => propertyName.indexOf('link') === 0
  );

  const links = () => {
    let arr = [];
    for (let i = 1; i <= totalLinks.length; i++) {
      arr.push(get(props, `fields[link${i}]`));
    }
    return arr;
  };

  const ctaElements = links();
  const showImage = image && image.value && image.value.src ? true : false;

  const infoBox = useRef(null),
    pictureElem = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    setTimeout(() => {
      const infoBoxAnimation = gsap.timeline();
      if (showImage) {
        const infoBoxElementsToAnim = [];
        pictureElem &&
          pictureElem.current &&
          infoBoxElementsToAnim.push(pictureElem.current);
        if (infoBoxElementsToAnim.length > 0) {
          infoBoxAnimation.fromTo(
            infoBoxElementsToAnim,
            {
              opacity: 0,
              ease: 'power1.out',
              y: '16px',
              paused: true,
              duration: '500ms',
            },
            {
              skewY: '0deg',
              y: '0px',
              opacity: 1,
            }
          );
          infoBoxAnimation.pause();
        }
      }

      let haveAnimatedOnce = false;
      gsap.timeline({
        scrollTrigger: {
          trigger: infoBox.current,
          start: 'top bottom',
          end: `+=${(window.androidHeight || window.innerHeight) * 0.2}px bottom`,
          scrub: true,
          once: true,
          onUpdate: self => {
            if (self.progress > 0.15) {
              infoBoxAnimation && infoBoxAnimation.play();
              haveAnimatedOnce = true;
            } else if (haveAnimatedOnce) {
              infoBoxAnimation && infoBoxAnimation.reverse();
            }
          },
        },
      });
    }, 100);
  });

  return (
    isFieldsEmpty ? <></> :
      <div
        className={`${classes.root} ${!showImage ? 'has-no-image' : ''}`}
        data-locator={COMPONENT_NAME}
        ref={infoBox}
      >
        <Grid className={classes.infoboxWrapper}>
          <Grid container className={`rowWrapper ${!showImage && classes.wrapperBackground}`} alignItems="center">
            {showImage ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={5}
                xl={4}
                className="imageWrapper"
              >
                <div className={classes.pictureWrap} ref={pictureElem}>
                  <Picture
                    media={{
                      image: image,
                      disableLazyLoad: true,
                    }}
                    className={'display-picture'}
                    isParalax={false}
                  />
                </div>
              </Grid>
            ) : (
              <i className={classes.infographicsImage} />
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={showImage ? 7 : 12}
              xl={showImage ? 8 : 12}
              className={classes.textWrapper}
            >
              <div className={'txt-wrap'}>
                {segmentText && segmentText.value && (
                  <TextRotateAnimation
                    condition={true}
                    animateSettings={{
                      willAnimate: true,
                      selfTrigger: true,
                      duration: '250ms',
                    }}
                  >
                    <Title text={segmentText} className="segment-title" />
                  </TextRotateAnimation>
                )}
                {title && title.value && (
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom={false}
                    className={'sec-title no-css-border'}
                    border={false}
                  >
                    <Text field={title} />
                  </Typography>
                )}
                {ctaElements && (
                  <Box className={classes.actionBlock}>
                    {ctaElements.map(
                      (item, i) =>
                        item.value &&
                        item.value.text && (
                          <div className={'btn-link'} key={i}>
                            <Button
                              onClick={() =>
                                addAnalyticsInfo(i)
                              }
                              className={classes.buttonInfoBox}
                              component="span"
                              buttonType="primary"
                              field={item}
                              hasBorder={false}
                              // This comments for Event page
                              themeColor={
                                contentType === EVENT_LIST_TEMPLATE_NAME
                                  ? EVENTS_THEME_COLOR
                                  : ''
                              }
                            />
                          </div>
                        )
                    )}
                  </Box>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(Infobox))
);
